import React, {Suspense} from "react";
import ReactDOM from "react-dom";
import {BrowserRouter} from "react-router-dom";

// import App from "./app";

const App = React.lazy(() => import(/* webpackChunkName: "App" */ './app'));

const Main = () => {
    return (
        <BrowserRouter>
            <Suspense fallback={<div className="loading"/>}>
                <App/>
            </Suspense>
        </BrowserRouter>
    );
};

ReactDOM.render(<Main />, document.getElementById('root'));

// ReactDOM.render(
//     <BrowserRouter>
//         <App/>
//     </BrowserRouter>,
//     document.getElementById("root")
// );
